
import Avatar from "../../Components/Avatar"
import { Link } from 'react-router-dom'
import { useFirestore } from '../../hooks/useFirestore'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom'

//styles
import './Project.css'


export default function Projectsummary({ project }) {
    const { deleteDocument } = useFirestore('projects')
    const { user } = useAuthContext();
    const navigate = useNavigate()

    const handleClick = (e) => {
        e.preventDefault()
        deleteDocument(project.id)
        navigate('/')
    }
    

    return (
        <div className="summary">
            <div>
                <Link to='/'><button className='btn back-btn'>Back</button></Link>
                <div className="project-summary"> 
                    <h2 className="page-title">{project.name}</h2>
                    <p>By: {project.createdBy.displayName}</p>
                    <p className="due-date">
                        Project Due By: {project.dueDate.toDate().toDateString()}
                    </p>
                    <p className="details">{project.details}</p>
                    <h4> Project is assigned to: </h4>
                    <div className="assigned-users">
                        {project.assignedUsersList.map(user => (
                            <div key={user.id}>
                                <Avatar src={user.photoURL} />
                            </div>
                        ))}
                    </div>
                </div>
                    {user.uid === project.createdBy.id && (
                        <>
                            <button className='btn' onClick={handleClick}>Remove Project</button>
                            <a href="https://tuanpham-dev.github.io/react-invoice-generator/" target="_blank" rel="noreferrer">
                                <button className='btn' id='invoice'>Create Invoice</button></a>
                        </>
                    )}
            </div>
        </div>
    )
}
