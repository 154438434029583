import { useState } from 'react';
import { useSignup } from '../../hooks/useSignup';

//styles
import './SignUp.css';

export default function SignUp () {
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [displayName, setDisplayName] = useState('');
        const [thumbnail, setThumbnail] = useState(null);
        const [thumbnailError, setThumbnailError] = useState(null)
        const { signup, error, isPending } = useSignup();
    

        const handleSubmit = (e) => {
            e.preventDefault();
            signup(email, password, displayName, thumbnail);
            setEmail('');
            setPassword('');
            setDisplayName('');
            setThumbnail(null);
        }

        const handleFileChange = (e) => {
            setThumbnailError(null)
            let selected = e.target.files[0];
            console.log(selected)
            if(!selected){
                setThumbnailError('Please select an image');
                return;
            }
            if(selected.size > 1000000){
                setThumbnailError('Image must be less than 1MB');
                return;
            }
            if(!selected.type.includes('image')) {
                setThumbnailError('Please select an image');
                return;
            }

            setThumbnailError(null);
            setThumbnail(selected);
            console.log('Thumbnail Updated')
        }
    
    
        return (
            <form className="auth-form" onSubmit={handleSubmit}>
                <h2>Sign Up</h2>
                <label>
                    <span>Email:</span>
                    <input 
                        required
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </label>
                <label>
                    <span>Password:</span>
                    <input 
                        required
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </label>
                <label>
                    <span>Display Name:</span>
                    <input 
                        required
                        type="text"
                        onChange={(e) => setDisplayName(e.target.value)}
                        value={displayName}
                    />
                </label>
                <label>
                    <span>Profile Picture:</span>
                    <input 
                        type="file"
                        onChange={handleFileChange}
                    />
                    {thumbnailError && <div className="error">{thumbnailError}</div>}   
                </label>
                {isPending && <button className="btn">Loading...</button>}
                {!isPending && <button className="btn">Submit</button>}
                {error && <div className="error">{error}</div>}
            </form>
        );
}

